import axios from "axios";
import { Context } from "../context";
import { redirect, useNavigate } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from "react-bootstrap/esm/AccordionItem";


const API_URL = "/api/queries/";

class ServiceQuery{
    
    async getProductsList(category, catTarif, numSage){
        try {
            let options
            const result = await axios.get(API_URL + "productslist",{params: {
                category, 
                catTarif,
                numSage,
            }, withCredentials: true 
            })
            if(result.status === 200){
                if(result.data !== ""){
                    options = result.data.map((el) => <option key={Object.values(el)} value={Object.values(el)}>{Object.values(el)}</option>)
                }else{options = null}
                
                return options
            }
        }catch (error) {
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
            throw 'Error'
        }   
    }
    async getAddressList(){
        try {
            let options
            const result = await axios.get(API_URL + "addresslist",{params: {}, withCredentials: true })
            // console.log("result",result)
            if(result.status === 200){
                if(result.data !== ""){
                    options = result.data.map((el) => <option key={el.Id} value={el.Id}>{el.Name}</option>)
                }else{options = null}
                
                return options
            }
        }catch (error) {
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
            throw 'Error'
        }   
    }
    async getAddressInfo(addressId){
        try {
            let options
            const result = await axios.get(API_URL + "address-details",{params: {addressId}, withCredentials: true 
            })
            if(result.status === 200){
                if(result.data !== ""){
                    options = result.data
                    // console.log(options)
                }else{options = null}
                
                return options
            }
        }catch (error) {
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
            throw 'Error'
        }   
    }
    async getUserInfo(){
        try {
            const result = await axios.get(API_URL + "userinfo",{ withCredentials: true 
            })
            if(result.status === 200){
                if(result.data !== ""){
                    sessionStorage.setItem("userInfo", JSON.stringify(result.data[0]))
                    return result.data[0]
                }else{return null}
            }
        }catch (error) {
            throw 'Error'
        }  
    }
    async getFormUrl(nom_form,nom_categorie,type_doc,address_id){
        try {
            console.log(nom_form,nom_categorie,type_doc,address_id)
            const result = await axios.get(API_URL + 'formurl',{params:{
                nom_form, nom_categorie, type_doc, address_id
            },withCredentials: true})
            return result.data
        } catch (error) {
            // console.log("error",error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async getFormProductUrl(nom_form, nom_categorie, order_id){
        try {
            const result = await axios.get(API_URL + 'form_product_url',{params:{
                nom_form, nom_categorie, order_id
            }, withCredentials: true})
            return result.data
        } catch (error) {
            // console.log("error",error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async getFormValidateUrl(order_id, address_id){
        try {
            const result = await axios.get(API_URL + 'form_validate_url',{params:{
                order_id, address_id
            }, withCredentials: true})
            return result.data
        } catch (error) {
            // console.log("error",error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
        }
    }
    //params typeDoc? opportunity, bdc, bl, facture 
    async getUserDocuments(type_doc){
        try {
            // console.log("in getUserDocuments")
            let header
            let rows
            const result = await axios.get(API_URL + 'getdocuments',{params:{type_doc}, withCredentials: true})
            console.log('result data', result.data)
            if(result.data.length !== 0){
                header = Object.keys(result.data[0])
                // console.log('header', header)
                header = <tr>{header.map((el) => (<th key={el} className="table-dark">{el}</th>))}</tr>

                console.log('header', header)
                rows = result.data.map((el) => Object.values(el))
                // console.log('rows', rows)
                rows = rows.map((el) => <tr key={el[1]}>{el.map((elem) => (<td>{elem}</td>))}</tr>)
                // console.log('rows', rows)

                return {header, rows}
            }else{
                return null
            }
            return result.data
        } catch (error) {
            // console.log("error",error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 404:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async getAllUserDocuments(){
        try {
            // console.log("in getUserDocuments")
            let tablesArray = []
            const result = await axios.get(API_URL + 'getalldocuments',{withCredentials: true})
            result.data.forEach(docArray => {
                let header
                let rows

                if(docArray.length !== 0){
                    header = Object.keys(docArray[0])
                    // console.log('header', header)
                    header = <tr>{header.map((el) => (<th key={el} className="table-dark">{el}</th>))}</tr>
    
                    // console.log('header', header)
                    rows = docArray.map((el) => Object.values(el))
                    console.log('rows', rows)
                    rows = rows.map((el) => <tr key={el[1]}>{el.map((elem) => (<td>{elem}</td>))}</tr>)
                    // console.log('rows', rows)
                    tablesArray.push({header, rows})
                }else{
                    return null
                }
            });
            return tablesArray
        } catch (error) {
            console.log("error",error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 404:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async postNewEmail(newEmail){
        try {
            const result = await axios.post(API_URL + 'update_email',{newEmail},{withCredentials: true})
            console.log(result)
            return result.data
        } catch (error) {
            console.log("error",error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw 'erreur inconnu'
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async postNewOrder(){
        try {
            const result = await axios.post(API_URL + 'new_order',{},{withCredentials: true})
            return result.data
        } catch (error) {
            console.log("error",error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 406: 
                    throw error.response.data
                case 500:
                    throw 'erreur inconnu'
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async getAllOrders(){
        // function handleAccordionClick(input){console.log('input', input); activeKeyValue = input}
        try {
            const result = await axios.get(API_URL + 'get_orders',{},{withCredentials: true})
            
            let body = []
            let bodyEnCours = result.data[0].map((el) => Object.values(el))
            bodyEnCours = bodyEnCours.map((el) => 
                {
                return (
                <Accordion.Item eventKey={el[0]} style={{'borderWidth': '2px'}}>
                    <Accordion.Header>Panier n°{el[0]} - {el[4].length} articles</Accordion.Header>
                    <Accordion.Body  style={{overflow: 'auto'}}>
                        <div className="product-accordion" style={{overflow: 'auto', maxHeight: '350px'}}>
                            {el[4].length > 0 &&
                                <table className="product-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Produit
                                            </th>
                                            <th>
                                                Détails
                                            </th>
                                            <th>
                                                Quantité
                                            </th>
                                            <th>
                                                &nbsp;
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {el[4].map((elem) => 
                                            <tr key={elem.id}>
                                                <td>
                                                    {elem.name}
                                                </td>
                                                <td className="details-div" >
                                                    <pre className="para-product">
                                                    {elem.glossaires}
                                                    </pre>
                                                </td>
                                                <td >
                                                    <div>
                                                        {/* <button style={{border:0}} id={"btn-product-qt-up_"+ elem.id}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-compact-up" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M7.776 5.553a.5.5 0 0 1 .448 0l6 3a.5.5 0 1 1-.448.894L8 6.56 2.224 9.447a.5.5 0 1 1-.448-.894z"/>
                                                            </svg>
                                                        </button> */}
                                                        <p style={{'margin': 0}}>{elem.quantités}</p>
                                                        {/* <button style={{border:0}} id={"btn-product-qt-down_"+ elem.id}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"/>
                                                            </svg>
                                                        </button> */}
                                                    </div>
                                                </td> 
                                                <td>
                                                    <button  className='btn btn-primary' id={"btn-product-trash_"+ elem.id}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            }
                        </div>
                        <div>
                            {el[4].length > 0 &&
                            <button className='btn btn-primary' style={{margin: '0 10px 0 10px'}} id={"btn-order-validate_"+ el[0]}>
                                Valider la commande
                            </button>
                            }
                            <button className='btn btn-primary' style={{margin: '0 10px 0 10px'}} id={"btn-product-add_"+ el[0]}>
                                Ajouter un produit
                            </button>
                            {/* {el[4].length > 0 &&
                            <button className='btn btn-primary' style={{margin: '0 10px 0 10px'}} id={"btn-order-download_"+ el[0]}>
                                Télécharger
                            </button>
                            } */}
                            <button  className='btn btn-primary' style={{margin: '0 10px 0 10px'}} id={"btn-order-trash_"+ el[0]}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                            </button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>)}
            )
            body.push(bodyEnCours)

            let bodyValider = result.data[1].map((el) => Object.values(el))
            bodyValider = bodyValider.map((el) => 
                <Accordion.Item eventKey={el[0]} style={{'borderWidth': '2px'}}>
                <Accordion.Header>Commande n°{el[0]}</Accordion.Header>
                <Accordion.Body style={{overflow: 'auto'}}>
                <div className="product-accordion" style={{overflow: 'auto', maxHeight: '350px'}}>
                    <table className="product-table">
                        <thead>
                            <tr>
                                <th>
                                    Produit
                                </th>
                                <th>
                                    Détails
                                </th>
                                <th>
                                    Quantité
                                </th>
                                <th>
                                    &nbsp;
                                </th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            {el[4].map((elem) => 
                                <tr key={elem.id}>
                                <td>
                                        {elem.name}
                                    </td>
                                    <td className="details-div" >
                                        <pre className="para-product">
                                        {elem.glossaires}
                                        </pre>
                                    </td>
                                    <td >
                                        {elem.quantités}
                                    </td> 
                                    <td>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                        </tfoot>
                    </table>
                </div>
                <div>
                    {el[4].length > 0 &&
                    <button className='btn btn-primary' style={{margin: '0 10px 0 10px'}} id={"btn-order-download_"+ el[0]}>
                        Télécharger
                    </button>
                    }
                </div>
                </Accordion.Body>
                </Accordion.Item>
            )
            body.push(bodyValider)
            return body
        } catch (error) {
            console.log("error",error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw 'erreur inconnu'
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async getOrdersIdList(req_type){
        try {
            const result = await axios.get(API_URL + 'get_orders_id',{params:{req_type}, withCredentials: true})
            let body = []
            let bodyEnCours = result.data[0].map((el) => Object.values(el))
            bodyEnCours = bodyEnCours.map((el) => 
                <option key={Object.values(el)} value={Object.values(el)}>n° {Object.values(el)}</option>
            )
            return bodyEnCours
        } catch (error) {
            console.log("error",error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw 'erreur inconnu'
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async countProducts(order_id){
        try {
            const result = await axios.get(API_URL + 'products_count',{params:{order_id}, withCredentials: true})
            return 'success'
        } catch (error) {
            console.log("error",error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 406:
                    throw error.response.data
                case 500:
                    throw 'erreur inconnu'
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async trashOrder(order_id){
        try {
            const result = await axios.post(API_URL + 'delete_order',{order_id},{withCredentials: true})
            return result.data
        } catch (error) {
            console.log("error",error)
            switch(error.response.status){
                case 404:
                    throw error.response.data
                case 500:
                    throw 'erreur inconnu'
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async getExport(order_id){
        try {
            const result = await axios.post(API_URL + 'generate_export',{order_id},{withCredentials: true, responseType: "blob"})

            const blob = new Blob([result.data], { type: "application/pdf" });

            // Créer une URL pour ce Blob
            const url = window.URL.createObjectURL(blob);
    
            // Créer un lien de téléchargement
            const a = document.createElement("a");
            a.href = url;
            a.download = `Panier_${order_id}.pdf`;
            document.body.appendChild(a);
            a.click();
    
            // Nettoyer après téléchargement
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            
            return 'success'
        } catch (error) {
            console.log("error",error)
            switch(error.response.status){
                case 404:
                    throw 'Panier/Commande introuvable'
                case 500:
                    throw 'erreur inconnu'
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async trashProduct(product_id){
        try {
            console.log('trashProduct', product_id)
            const result = await axios.post(API_URL + 'delete_product',{product_id},{withCredentials: true})
            console.log(result.data)
            return result.data
        } catch (error) {
            console.log("error",error)
            switch(error.response.status){
                case 404:
                    throw error.response.data
                case 500:
                    throw 'erreur inconnu'
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async updateQuantity(product_id, change){
        try {
            console.log('updateQuantity', product_id)
            const result = await axios.post(API_URL + 'update_quantity',{product_id, change},{withCredentials: true})
            console.log(result.data)
            return result.data
        } catch (error) {
            console.log("error",error)
            switch(error.response.status){
                case 404:
                    throw error.response.data
                case 500:
                    throw 'erreur inconnu'
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async getSharedDocs(catTarif){
        try {
            let options
            const result = await axios.get(API_URL + "get_shared_docs",{params: {
                catTarif,
            }, withCredentials: true 
            })
            if(result.status === 200){
                switch (result.data[0]) {
                    case 'success':
                        return(['Vous allez recevoir un mail avec un lien vers le dossier partagé, connectez-avec l\'adresse qui as reçu le mail et suivez les instructions', result.data[1]])
                        break;
                    case 'existant':
                        return(['Vous allez être rediriger vers le dossier partagé, utiliser l\'adresse mail de votre compte pour vous connectez', result.data[1]])
                        break;
                
                    default:
                        throw ["erreur inconnu"]
                        break;
                }
                return result
            }
        }catch (error) {
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
            throw 'Error'
        }   
    }
}


export default new ServiceQuery();