import React, { useState, useEffect, useContext } from 'react'
import ServiceQuery from '../services/service_query'
import { redirect, useNavigate, useLocation} from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { AuthContext } from '../Context/authProvider'


function Commandes () {
    
  const [ordersList, setOrdersList] = useState([])
  const [header, setHeader] = useState(null)
  const [docStatus, setDocStatus] = useState('')
  const [caption, setCaption] = useState('')
  const [update, setUpdate] = useState(false)
  const navigate = useNavigate()
  const {state} = useLocation()
  const [successMsg, setSuccessMsg] = useState("")
  const [refreshStatus, setRefreshStatus] = useState("")
  const [queryError, setQueryError] = useState("")
  const [currentState, setCurrentState] = useState(null)
  const {auth, authDispatch} = useContext(AuthContext)
  var defaultOrderKey = 0
  if(state?.order_id){
    defaultOrderKey = state?.order_id
  }
  
  
  useEffect(() => {

    async function callService() {
      setRefreshStatus('Actualisation en cours...')
      let returnObject = await ServiceQuery.getAllOrders()

      setOrdersList(returnObject)
      setRefreshStatus('')
      // setOrdersList(body)
    }
    callService()
  }, [update]);

  async function handleNewOrderOnClick(event){
    try {
      let returnObject = await ServiceQuery.postNewOrder()
      setCurrentState(returnObject)
      // console.log('returnObject', returnObject)
      setSuccessMsg('Un nouveau panier à était créer avec succès')
      setUpdate(update === true ? false : true)
    } catch (error) {
      setQueryError(error);setSuccessMsg('') 
    }
  }

  async function formSubmitHandler(event){
    event.preventDefault()
    try {
      let eventId = event.nativeEvent.submitter.id.split('_')
      switch (eventId[0]) {
        case 'btn-product-add':
          let returnValue = await ServiceQuery.countProducts(eventId[1])
          if (returnValue === 'success'){
            setQueryError('')
            navigate('/choixproduit1', {state : {orderId: eventId[1]}})
          }else{setQueryError(returnValue);setSuccessMsg('')}
          break;
        case 'btn-order-trash':
          var result = window.confirm("Voulez-vous enlever ce panier (et les produits associés) ?");
          if (result === true){
            let returnValue = await ServiceQuery.trashOrder(eventId[1])
            setSuccessMsg('Le panier à bien était supprimé')
            setUpdate(update === true ? false : true)
            setQueryError('')
          }
          break;
        case 'btn-order-download':
          var result = window.confirm("Voulez-vous télécharger le fichier ?");
          if (result === true){
            let returnValue = await ServiceQuery.getExport(eventId[1])
            setSuccessMsg('Le panier à bien était télécharger')
            // setUpdate(update === true ? false : true)
            setQueryError('')
          }
          break;
        case 'btn-product-trash':
          var result = window.confirm("Voulez-vous supprimez ce produit de votre panier ?");
          if (result === true){
            let returnValue = await ServiceQuery.trashProduct(eventId[1])
            setSuccessMsg('Le produit à bien était supprimé')
            setUpdate(update === true ? false : true)
            setQueryError('')
          }
          // console.log('result', result)
          break;
        case 'btn-product-qt-up':
          var result = window.confirm("Voulez-vous modifier la quantité pour ce produit ?");
          if (result === true){
            let returnValue = await ServiceQuery.updateQuantity(eventId[1], 'up')
            setSuccessMsg('La quantité a bien était modifié')
            setUpdate(update === true ? false : true)
            setQueryError('')
          }
          // console.log('result', result)
          break;
        case 'btn-product-qt-down':
          var result = window.confirm("Voulez-vous modifier la quantité pour ce produit ?");
          if (result === true){
            let returnValue = await ServiceQuery.updateQuantity(eventId[1], 'down')
            setSuccessMsg('La quantité a bien était modifié')
            setUpdate(update === true ? false : true)
            setQueryError('')
          }
          // console.log('result', result)
          break;
        case 'btn-order-validate':
          var result = window.confirm("Voulez-vous valider votre panier ?");
          if (result === true){
            navigate('/validateorder', {state : {orderId: eventId[1]}})
          }
          // console.log('result', result)
          break;
        default:
          // console.log('default')
          break;
      }
    } catch (error) {
        setQueryError(error)
    }
    // console.log(event.nativeEvent.submitter.id)
  }

  return (
      <div>          
        <div className="alert alert-secondary" style={{marginBottom:'10px'}}>
        Si vous rencontrez un problème avec le système de paniers ou avez une suggestion, contactez le <a href='mailto:flux@socotex.fr'>SUPPORT</a>. Vous pouvez aussi temporairement utiliser <button onClick={(e) => {navigate('/choixproduit')}}>l'ancien système de commande</button><br/>
        <span style={{fontSize: '1.1em'}}><u><b>RAPPEL: Pour nous communiqué votre commande, vous devez valider votre panier, bouton "Valider la commande" dans le panier et remplir le formulaire de validation.</b></u></span>
        </div>
        <h1 className="text-center mt-2">Mes Paniers</h1>
        <div className='container text-center' style={{padding:"0px"}}>
          <div className='row' >
            <div className='col' style={{margin: '0px 0 10px 0 '}}>
                <button style={{margin: '0 10px 0 10px'}} id="nouvelle_commande" className="btn btn-primary" type="button" onClick={handleNewOrderOnClick}>Nouveau panier</button>
                <button style={{margin: '0 10px 0 10px'}} id="refresh_order" className='btn btn-primary' type='button' onClick={(e) => {setUpdate(update === true ? false : true)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                  </svg>
                </button>
                <b><a href={process.env.PUBLIC_URL + "/Guide_Paniers_v2.pdf"} style={{margin: '0 10px 0 10px'}} className="btn btn-primary" target="_blank" rel='noreferrer noopener'>Guide d'utilisation Paniers</a></b>
        
            </div> 
            {successMsg && <div className="alert alert-info" style={{'margin': 0}}>{successMsg}</div>}
            {queryError && <div className="alert alert-danger" style={{'margin': 0}}>{queryError}</div>}
            {refreshStatus && <div className="alert alert-warning" style={{'margin': 0}}>{refreshStatus}</div>}
            {state?.message_form && <div className="alert alert-info" style={{'margin': 0}}>Votre produit a bien était enregistrer, si le produit n'apparait pas dans votre panier, attendais quelque secondes et appuyer sur le bouton actualiser, si le produit n'apparait toujours pas, contactez le <a href='mailto:flux@socotex.fr'>SUPPORT</a></div>}
          </div>
        </div>
        <Accordion defaultActiveKey={["en_cours"]} >
          <form onSubmit={formSubmitHandler}>
          <Accordion.Item style={{'marginBottom': '10px', 'borderWidth': '3px'}} eventKey="en_cours">
            <Accordion.Header><b>PANIERS EN COURS</b></Accordion.Header>
            {/* <div className="alert alert-info">Le détail de chaque produit peux changer suite</div> */}
            <Accordion.Body >
              <Accordion defaultActiveKey={defaultOrderKey} activeKey={currentState ?? Number(defaultOrderKey)} onSelect={(e) => {setCurrentState(e); console.log('e', e); console.log('currentState', currentState)}}>
                {ordersList[0]}
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
          
          <Accordion.Item style={{'marginBottom': '10px', 'borderWidth': '3px'}} eventKey="valider">
            <Accordion.Header><b>COMMANDES VALIDÉES</b></Accordion.Header>
            <Accordion.Body>
              <Accordion>
                {ordersList[1]}
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
          </form>
        </Accordion>
      </div>

  )
}

export default Commandes