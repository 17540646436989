import React, { useState, useEffect, useContext } from 'react'
import ServiceQuery from '../services/service_query'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import { AuthContext } from '../Context/authProvider'

function ChoixProduit () {

  const navigate = useNavigate()
  const [productsList, setProductsList] = useState(null)
  const [gammeList, setGammeList] = useState(null)
  const [addressList, setAddressList] = useState(null)
  const [addressId, setAddressId] = useState(null)
  const [addressDetails, setAddressDetails] = useState(null)
  const [selected, setSelected] = useState("");
  const [queryError, setQueryError] = useState("")
  const {auth, authDispatch} = useContext(AuthContext)
  /** This will be used to create set of options that user will see */
  let options = [];
  let details = undefined

  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    async function callService() {
        // console.log("in callService")
        let returnObject = await ServiceQuery.getAddressList()
        setAddressList(returnObject)

        // console.log(lignes)
    }
    callService()
  }, []);

  const changeSelectOptionHandler = async (event) => {
    if(event.target.value !== "Category"){
      options = await ServiceQuery.getProductsList(event.target.value,auth.catTarif)
      setProductsList(options)
    }else{setProductsList(null)}
  }
  const changeBibSelectOptionHandler = async (event) => {
    if(event.target.value !== "Category"){
      setProductsList(null)
      switch (event.target.value) {
        case 'Conf':
          setGammeList(
            [<option value="Acrylique">Acrylique</option>,
            <option value="PVC">PVC</option>]
          )
          options = await ServiceQuery.getProductsList('Acrylique',auth.catTarif)
          setProductsList(options)
          break;
        case 'Plein-Air':
          setGammeList([<option value="Parasols">Parasols</option>])
          options = await ServiceQuery.getProductsList('Parasols',auth.catTarif)
          setProductsList(options)
          break;
        case 'IDEEA':
          // setGammeList([<option value="Parasols">Parasols</option>])
          break;
        case 'Stores-ext':
          setGammeList([<option value="Stores-banne">Stores Bannes</option>])
          options = await ServiceQuery.getProductsList('Stores-banne',auth.catTarif)
          setProductsList(options)
          break;
        case 'Moustiquaires':
          setGammeList([<option value="MoustiquairesRapide">Moustiquaires - délais rapide</option>, <option value="MoustiquairesDelais">Moustiquaires - avec délais</option>])
          options = await ServiceQuery.getProductsList('MoustiquairesRapide',auth.catTarif)
          setProductsList(options)
          break;
      
        default:
          break;
      }
      // options = await ServiceQuery.getProductsList(event.target.value,auth.catTarif)
      // setProductsList(options)
    }else{setGammeList(null)}
  }
  const changeGammeSelectOptionHandler = async (event) => {
    if(event.target.value !== "Category"){
      options = await ServiceQuery.getProductsList(event.target.value,auth.catTarif)
      setProductsList(options)
    }else{setProductsList(null)}
  }

  const changeAddressOptionHandler = async (event) => {
    setAddressDetails(null)
    setAddressId(event.target.value)
    if(event.target.value !== '' && event.target.value !== 'Autre'){
      details = await ServiceQuery.getAddressInfo(event.target.value)
      setAddressDetails(details)
    }else{
      // setAddressDetails(null)
    }
    // console.log(addressId)
  }

  const handleSubmit = async (event) =>{
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);   
    const formJson = Object.fromEntries(formData.entries());
    // console.log(formJson)
    try {
      var formUrl = await ServiceQuery.getFormUrl(formJson.product, formJson.gammeProd, formJson.catRequest, formJson.address)
    } catch (error) {
      setQueryError(error)
    }
    navigate('/formulaire',{state:{formUrl, formName: formJson.product, categoryRequest: formJson.catRequest }})

  }

  return (
    <div style={{'textAlign':'center', 'display':'flex','justifyContent':'center','alignItems':'center','flexDirection':'column'}}>
      <div className="alert alert-danger">Ce processus de commande est obsolète et il sera complètement désactivé fin avril. Si vous rencontrez un problème avec le nouveau processus (Mes Paniers), n'oubliez pas de contacter le <a href="mailto:flux@socotex.fr">SUPPORT</a></div>
      <div style={{'width':'fit-content','textAlign':'left'}}>
        
        {queryError && <div className="alert alert-danger">{queryError}</div>}
        
        <form id="formFormulaire" onSubmit={handleSubmit}>
          <h1 className="text-center mt-2">Choix produit</h1><ul className="list-group list-group-flush"></ul>
          <div hidden className="list select-div">
          <label  htmlFor="catRequest">Choisissez un type de demande :</label>
          <select className="form-select form-select-lg select-second-item" style={{'width':'fit-content','display':'inline', 'margin': '0.5em', 'borderColor':'#333'}} name="catRequest" id="catRequest">
              <option value="Commande">Commande</option>
          </select>
          </div>
          <div className="list select-div">
          <label htmlFor="catProd">Choisissez une Bibliothèque :<br></br></label>
          <select className="form-select form-select-lg select-second-item" style={{'width':'fit-content', 'display':'inline', 'margin': '0.5em' , 'borderColor':'#333'}} form="formFormulaire" name="catProd" id="catProd" required onChange={changeBibSelectOptionHandler}>
              <option value=""  hidden>Bibliothèques</option>
              <option value="Conf">Confection</option>
              <option value="Plein-Air">Plein-Air</option>
              {/* <option value="IDEEA">IDEEA</option> */}
              <option value="Stores-ext">Stores extérieurs</option>
              <option value="Moustiquaires">Moustiquaires</option>
          </select>
          </div>
          <div className="list select-div">
          <label htmlFor="gammeProd">Choisissez une gamme :<br></br></label>
          <select className="form-select form-select-lg select-second-item" style={{'width':'fit-content', 'display':'inline', 'margin': '0.5em' , 'borderColor':'#333'}} form="formFormulaire" name="gammeProd" id="gammeProd" required onChange={changeGammeSelectOptionHandler}>
            {gammeList}
          </select>
          </div>
          <div className="list select-div">
            <label htmlFor="product">Choisissez un produit :</label>
            <select className="form-select form-select-lg select-second-item" style={{'width':'fit-content', 'display':'inline', 'margin': '0.5em', 'borderColor':'#333'}} form="formFormulaire" name="product" id ="product" required>
              {
                productsList
              }
            </select>
          </div>
          <div className="list select-div">
            <label htmlFor="address">Choisissez une adresse de livraison:</label>
            <select className="form-select form-select-lg select-second-item" style={{'width':'fit-content', 'display':'inline', 'margin': '0.5em', 'borderColor':'#333'}} form="formFormulaire" name="address" id ="address" required onChange={changeAddressOptionHandler}>
              <option value="" hidden>Adresse</option>
              {addressList}
              <option style={{ 'color':'blue'}}value="Autre">Nouvelle adresse</option>
            </select>
          </div>
          <div className="" style={{'textAlign':'center','marginTop':'1em'}}>
            <button type="submit" style={{paddingLeft:'1.5em',paddingRight:'1.5em'}}className="btn btn-primary" disabled={''}>Valider</button>
          </div>
        </form>


      </div>
      {
          addressDetails?.sage__Rue__c ? 
          <>

            <Table style={{'marginTop':'20px'}} striped hover>
              <thead>
                <tr>
                  <th key='Adresse'>Adresse</th>
                  <th key='Code postal'>Code postal</th>
                  <th key='Ville'>Ville</th>
                  <th key='Pays'>Pays</th>
                </tr>
              </thead>
              <tbody>
              <tr key='address'>
                <td>{addressDetails.sage__Rue__c}</td>
                <td>{addressDetails.sage__Code_postal__c}</td>
                <td>{addressDetails.sage__Ville__c}</td>
                <td>{addressDetails.sage__Pays2__c}</td>
              </tr>
              </tbody>
            </Table>
        </>
        :
        
          addressId === "Autre" ? 
          <>
            <div style={{'marginTop':'20px'}} className="alert alert-info">Vous pourrez entrer la nouvelle adresse dans le formulaire de commande</div>
          </> 
          : 
          <>
          </>
        }
    </div>

  ) 
    
}

export default ChoixProduit
