import React, { useEffect, useState, useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Context } from '../context'
import ServiceAuth from '../services/service_auth'
import ServiceQuery from '../services/service_query'
import { Navigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { AuthContext } from '../Context/authProvider'

function ContenuSupp () {

  const divRef = useRef(null);
  const [sharedStatus, setSharedStatus] = useState("")
  const [displayedContent, setDisplayContent] = useState("")
  const {auth, authDispatch} = useContext(AuthContext)

  const handleFullscreen = () => {
    if (divRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        divRef.current.requestFullscreen();
      }
    }
  };

  async function handleButtonOnClickBank(event){
    var result = window.confirm("Voulez-vous accéder a la banque d'image ?");
    if (result === true){
      setDisplayContent(
        <div style={{minHeight: '100vh', display: "flex", flexDirection: "column" }}>
            <div className="alert alert-secondary mx-5 mb-0">
                <p><b>Utiliser le mot de passe suivant pour accéder à la banque d'image :</b></p>
                <p><span className="spoiler" style={{ borderTop: "5px"}}>socotex14600</span></p>
            </div>
            <iframe
                title="socotex banque photo"
                src="https://www.socotex.net/socotex-client"
                style={{
                    flex: 1,  // Permet à l'iframe de prendre tout l'espace restant
                    width: "100%",
                    border: 'solid',
                    overflow: 'hidden',
                    borderRadius: '5px',
                }}
            />
        </div>)
    }
  }

  return (



      <div className="list-group">
        { <h3 className="text-center mt-2" style={{marginBottom: '5px'}}><button style={{ paddingLeft: '1.5em', paddingRight: '1.5em',  }} className="btn btn-primary" disabled={''} onClick={handleButtonOnClickBank}><b>Accès banque d'image Socotex</b></button></h3> }
        {displayedContent}

      </div>
      
  )
}

export default ContenuSupp