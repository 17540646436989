// import logo from './logo.svg';
import './App.css';
import './styles/body.css'
import { useContext, useEffect, useRef } from 'react'
import { Route, Routes, redirect, useNavigate } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'
import Header from './components/Header';
import Footer from './components/Footer'
import Login from './pages/Login'
import AccueilClient from './pages/AccueilClient'
import NotFound from './pages/NotFound'
import Accueil from './pages/Accueil'
import AccueilAdmin from './pages/AccueilAdmin'
import ChoixProduit from './pages/ChoixProduit'
import ChoixProduit1 from './pages/ChoixProduit1'
import ValidateOrder from './pages/validateorder'
import Formulaire from './pages/Formulaire';
import Auth from './pages/Auth1'
import ResetPassword from './pages/ResetPassword'
import ChangePassword from './pages/changePassword'
import Register from './pages/Register'
import Documents from './pages/Documents'
import Commandes from './pages/Commandes'
import AdminExport from './pages/AdminExport';
import MentionsLegales from './pages/MentionsLegale'
import ContenuSupp from './pages/ContenuSupp'
import { AuthContext } from './Context/authProvider';
import ServiceQuery from './services/service_query';
import axios from 'axios';



function App() {

  const navigate = useNavigate()
  const shouldLog = useRef(true);
  const {auth, authDispatch} = useContext(AuthContext)

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
    loadUser();
    }
  }, []);
  
  const loadUser = async () => {
    if(document.cookie.match('loggedIn') && auth.name === ''){
      let returnValue = await ServiceQuery.getUserInfo()
      authDispatch({type: 'updateUser', payload: returnValue})
      navigate('/accueilclient')
    }
    
    axios.interceptors.response.use(undefined, 
      function axiosRetryInterceptor(err){
        // console.log("error interceptor",err)
        switch (err.response.request.status){
          case 401:
            if(err.config.url === '/api/validate' || err.config.url === '/api/register'){
              authDispatch({type: 'logout'})
              navigate('/register',{state:{errMsg: JSON.parse(err.response.request.response).error}})
            }else{
              authDispatch({type: 'logout'})
              navigate('/login',{state:{errMsg: JSON.parse(err.response.request.response).error}})
            }
            return Promise.reject(err)
          default:
            return Promise.reject(err)
        }
      }
    );
  }
  
  return (
    <div className="App bg-light">
      <CookieConsent debug={false} location={"top"} buttonText={"Je comprend"} style={{fontSize:'1.2em'}} containerClasses="alert col-lg-12" contentClasses="text-capitalize" overlay>
        Ce site utilise des cookies qui permettent le bon fonctionnement du site. 
      </CookieConsent>
      <Header/>
      <div className="p3 bg-body main-body">
        <Routes>
          <Route path="/" element={<Accueil/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/reset-password" element={<ResetPassword/>}/>
          <Route path="/change-password" element={<ChangePassword/>}/>
          <Route path="/mentions-legales" element={<MentionsLegales/>}/>
          <Route element={<Auth allowedRoles={['Client','Testeur']}/>}>
            <Route path ="/accueilclient" element={<AccueilClient/>}/>
          </Route>
          <Route element={<Auth allowedRoles={['Administrateur']}/>}>
            <Route path ="/accueiladmin" element={<AccueilAdmin/>}/>
          </Route>
          <Route element={<Auth allowedRoles={['Administrateur']}/>}>
            <Route path ="/admin-export" element={<AdminExport/>}/>
          </Route>
          <Route element={<Auth allowedRoles={['Client','Testeur']}/>}>
            <Route path ="/documents" element={<Documents/>}/>
          </Route>
          <Route element={<Auth allowedRoles={['Client','Testeur']}/>}>
            <Route path ="/choixproduit" element={<ChoixProduit/>}/>
          </Route>
          <Route element={<Auth allowedRoles={['Client', 'Testeur']}/>}>
            <Route path ="/choixproduit1" element={<ChoixProduit1/>}/>
          </Route>
          <Route element={<Auth allowedRoles={['Client', 'Testeur']}/>}>
            <Route path ="/validateorder" element={<ValidateOrder/>}/>
          </Route>
          <Route element={<Auth allowedRoles={['Client', 'Testeur']}/>}>
            <Route path ="/commandes" element={<Commandes/>}/>
          </Route>
          <Route element={<Auth allowedRoles={['Client','Testeur','Administrateur']}/>}>
            <Route path ="/formulaire" element={<Formulaire/>}/>
          </Route>
          <Route element={<Auth allowedRoles={['Client','Testeur']}/>}>
            <Route path ="/contenusupp" element={<ContenuSupp/>}/>
          </Route>
          
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
