import React, { useState, useEffect, useContext } from 'react'
import ServiceQuery from '../services/service_query'
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../Context/authProvider'

function ChoixProduit1 () {

  const navigate = useNavigate()
  const {state} = useLocation()
  const [selectedOrder, setSelectedOrder] = useState(state.orderId)
  const [productsList, setProductsList] = useState(null)
  const [gammeList, setGammeList] = useState(null)
  const [ordersList, setOrdersList] = useState(null)
  const [queryError, setQueryError] = useState("")
  const {auth, authDispatch} = useContext(AuthContext)

  let options = [];

  useEffect(() => {
    async function callService() {
      let returnObject = await ServiceQuery.getOrdersIdList('addProduct')
      setOrdersList(returnObject)
    }
    callService()
  }, []);

  const changeSelectOptionHandler = async (event) => {
    if(event.target.value !== "Category"){
      options = await ServiceQuery.getProductsList(event.target.value, auth.catTarif, auth.numSage)
      setProductsList(options)
    }else{setProductsList(null)}
  }
    const changeBibSelectOptionHandler = async (event) => {
      if(event.target.value !== "Category"){
        setProductsList(null)
        switch (event.target.value) {
          case 'Conf':
            setGammeList(
              [<option value="AcryliqueMono">Acrylique</option>,
              <option value="PVCMono">PVC</option>]
            )
            options = await ServiceQuery.getProductsList('AcryliqueMono', auth.catTarif, auth.numSage)
            setProductsList(options)
            break;
          case 'Plein-Air':
            setGammeList([<option value="ParasolsMono">Parasols</option>])
            options = await ServiceQuery.getProductsList('ParasolsMono', auth.catTarif, auth.numSage)
            setProductsList(options)
            break;
          case 'IDEEA':
            // setGammeList([<option value="Parasols">Parasols</option>])
            break;
          case 'Stores-ext':
            setGammeList([<option value="Stores-banneMono">Stores Bannes</option>])
            options = await ServiceQuery.getProductsList('Stores-banneMono', auth.catTarif, auth.numSage)
            setProductsList(options)
            break;
          case 'Moustiquaires':
            setGammeList([<option value="MoustiquairesRapideMono">Moustiquaires - délais rapide</option>, <option value="MoustiquairesDelaisMono">Moustiquaires - avec délais</option>])
            options = await ServiceQuery.getProductsList('MoustiquairesRapideMono', auth.catTarif, auth.numSage)
            setProductsList(options)
            break;
        
          default:
            break;
        }
        // options = await ServiceQuery.getProductsList(event.target.value,auth.catTarif)
        // setProductsList(options)
      }else{setGammeList(null)}
    }
    const changeGammeSelectOptionHandler = async (event) => {
      if(event.target.value !== "Category"){
        options = await ServiceQuery.getProductsList(event.target.value, auth.catTarif, auth.numSage)
        setProductsList(options)
      }else{setProductsList(null)}
    }
  const changeOrderSelectHandler = async (e) => {
    setSelectedOrder(e.target.value)
  }

  const handleSubmit = async (event) =>{
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);   
    const formJson = Object.fromEntries(formData.entries());
    // console.log(formJson)
    try {
      // var formUrl = await ServiceQuery.getFormProductUrl(formJson.product, formJson.catProd, formJson.order)
      var formUrl = await ServiceQuery.getFormProductUrl(formJson.product, formJson.gammeProd, formJson.order)
    } catch (error) {
      setQueryError(error)
    }
    navigate('/formulaire',{state:{formUrl, formName: formJson.product, order_id: formJson.order }})

  }

  return (
     <div style={{'textAlign':'center', 'display':'flex','justifyContent':'center','alignItems':'center','flexDirection':'column'}}>
      {/* <div className="alert alert-info">Les bons de commandes pour toile PVC (Store enrouleur et Store Banne) sont à présent disponible,
                 retrouvé les dans la catégorie confection PVC.</div> */}
      <div style={{'width':'fit-content','textAlign':'left'}}>
        
        {queryError && <div className="alert alert-danger">{queryError}</div>}
        
        <form id="formFormulaire" onSubmit={handleSubmit}>
          <h1 className="text-center mt-2">Choix produit</h1><ul className="list-group list-group-flush"></ul>
          <div className="list select-div">
          <label htmlFor="order">Choisissez la commande :</label>
          <select className="form-select form-select-lg select-second-item" value={selectedOrder} style={{'width':'fit-content','display':'inline', 'margin': '0.5em', 'borderColor':'#333'}} name="order" id="order" onChange={changeOrderSelectHandler}>
            {ordersList}
          </select>
          </div>
          {/* <div className="list select-div">
          <label htmlFor="catProd">Choisissez une catégorie :<br></br></label>
          <select className="form-select form-select-lg select-second-item" style={{'width':'fit-content', 'display':'inline', 'margin': '0.5em' , 'borderColor':'#333'}} form="formFormulaire" name="catProd" id="catProd" required onChange={changeSelectOptionHandler}>
              <option value=""  hidden>Catégorie</option>
              <option value="AcryliqueMono">Confection Acrylique</option>
              <option value="PVCMono">Confection PVC</option>
              <option value="IDEEAMono">IDEEA</option>
              <option value="Plein-Air">Plein-Air</option>
          </select>
          </div>
          <div className="list select-div">
            <label htmlFor="product">Choisissez un produit :</label>
            <select className="form-select form-select-lg select-second-item" style={{'width':'fit-content', 'display':'inline', 'margin': '0.5em', 'borderColor':'#333'}} name="product" id ="product" required>
              {
                productsList
              }
            </select>
          </div> */}
          <div className="list select-div">
          <label htmlFor="catProd">Choisissez une Bibliothèque :<br></br></label>
          <select className="form-select form-select-lg select-second-item" style={{'width':'fit-content', 'display':'inline', 'margin': '0.5em' , 'borderColor':'#333'}} form="formFormulaire" name="catProd" id="catProd" required onChange={changeBibSelectOptionHandler}>
              <option value=""  hidden>Bibliothèques</option>
              <option value="Conf">Confection</option>
              <option value="Plein-Air">Plein-Air</option>
              {/* <option value="IDEEA">IDEEA</option> */}
              <option value="Stores-ext">Stores extérieurs</option>
              <option value="Moustiquaires">Moustiquaires</option>
          </select>
          </div>
          <div className="list select-div">
          <label htmlFor="gammeProd">Choisissez une gamme :<br></br></label>
          <select className="form-select form-select-lg select-second-item" style={{'width':'fit-content', 'display':'inline', 'margin': '0.5em' , 'borderColor':'#333'}} form="formFormulaire" name="gammeProd" id="gammeProd" required onChange={changeGammeSelectOptionHandler}>
            {gammeList}
          </select>
          </div>
          <div className="list select-div">
            <label htmlFor="product">Choisissez un produit :</label>
            <select className="form-select form-select-lg select-second-item" style={{'width':'fit-content', 'display':'inline', 'margin': '0.5em', 'borderColor':'#333'}} form="formFormulaire" name="product" id ="product" required>
              {
                productsList
              }
            </select>
          </div>
          <div className="" style={{'textAlign':'center','marginTop':'1em'}}>
            <button type="submit" style={{paddingLeft:'1.5em',paddingRight:'1.5em'}}className="btn btn-primary" disabled={''}>Valider</button>
          </div>
        </form>
      </div>
    </div>

  ) 
    
}

export default ChoixProduit1