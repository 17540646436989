import React, { useContext, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import ServiceAuth from '../services/service_auth'
import { AuthContext } from '../Context/authProvider';

function Login() {

    const {state} = useLocation()
    const navigate = useNavigate()
    const [authError, setAuthError] = useState('')
    const {auth, authDispatch} = useContext(AuthContext)
    const [searchParams, setSearchParams] = useSearchParams();
    const success = searchParams.get("success")
    const error = searchParams.get("error")
    return (
        <div className="d-flex justify-content-center" style={{flexDirection:'column', alignItems:'center'}}>
            <div className="col-12 col-sm-10 col-md-8 col-lg-4">
                {/* <div className="alert alert-warning"><b style={{fontSize:'1.2em'}}>SOCOTEX sera fermé du 24 décembre au 1er janvier inclus</b> </div>*/}
                <h1 className="text-center mt-2">Connexion</h1>
                <Formik
                    initialValues={{ login: '', password: '' }}
                    validationSchema={Yup.object({
                        login: Yup.string()
                            .required('Un identifiant est nécessaire'),
                        password: Yup.string()
                            .required('Un mot de passe est nécessaire'),
                    })}
                    onSubmit={async ({ login, password }) => {
                        try {
                            let data = await ServiceAuth.login(login, password)
                            authDispatch({type:'setUser', payload: data[0]})
                            switch (data[0].role){
                                case "Administrateur":
                                    navigate('/accueiladmin')
                                    break;
                                case "Client":
                                    navigate('/accueilclient')
                                    break;
                                case "Testeur":
                                    navigate('/accueilclient')
                                    break;
                                default:
                                    navigate('/')
                            }
                        } catch (error) {
                            setAuthError(error)
                        } 
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            {state?.errMsg ?
                            state?.errMsg && <div className="alert alert-danger">{state?.errMsg}</div>
                            :
                            authError && <div className="alert alert-danger">{authError}</div>

                            }

                            {/* {state?.errMsg && <div className="alert alert-danger">{state?.errMsg}</div>} */}
                            {state?.success && <div className="alert alert-info">{state?.success}</div>}
                            {success && <div className="alert alert-info">{success}</div>}
                            {error && <div className="alert alert-danger">{error}</div>}
                            {/* {authError && <div className="alert alert-danger">{authError}</div>} */}
                            <div className="mb-3">
                                <label htmlFor="login" className="form-label">Identifiant</label>
                                <Field type="text" className="form-control" id="login" name="login" autoComplete="username"/>
                                <ErrorMessage name="login" component="div" className="alert alert-warning" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Mot de passe</label>
                                <Field type="password" className="form-control" id="password" name="password" autoComplete="current-password"/>
                                <ErrorMessage name="password" component="div" className="alert alert-warning" />
                            </div>
                            <div className="d-grid gap">
                                <button type="submit" className="btn btn-primary btn-expand" disabled={isSubmitting}>Valider</button>
                            </div>
                            <div className="d-grid gap" style={{marginTop: 5 + 'px'}}>
                                <Link to="/reset-password">Mot de passe oublié ?</Link>
                                <br/>
                                <p>
                                    <b>Vous n'avez pas reçu vos identifiants?</b><br/>
                                    Envoyer un mail au <a href='mailto:flux@socotex.fr'>SUPPORT</a> avec votre code client (présent sur vos documents de commande (ARC, BL, Facture, etc...)).
                                </p>
                                {/* <p><br/><br/>Cet espace commande est réservé aux clients de l'entreprise Socotex.
                                    Si vous avez un compte client : vous allez recevoir un e-mail : accès à socotex-espace-pro
                                        Si vous n'êtes pas encore client : rendez-vous sur notre formulaire 
                                        <a href="https://www.socotex.net/contact-socotex"> Contact </a>, vous serez contacté
                                        par le commercial en charge de votre secteur pour la mise en place de votre compte client.
                                </p> */}
                            </div>
                            <a href="https://socotex.net/blog" target="_blank" rel='noreferrer noopener'>
                                <h3 style={{'MarginTop': '10px' }}><b>Découvrez nos actualités<br/></b></h3>
                            </a>  
                            {/* <a href={'https://www.socotex.net/post/offre-sp%C3%A9ciale-une-box-tahoma-offerte-pour-toute-commande-de-pergola-motoris%C3%A9e-en-mars'} target="_blank" rel='noreferrer noopener'><img src={process.env.PUBLIC_URL + '/opMars2025.jpg'} alt="Socotex 2025" width="100%" height="fitContent" style={{marginBottom:'10px'}}></img></a> */}
                            <img src={process.env.PUBLIC_URL + '/signatureSummer.jpg'} alt="Socotex été 2025" width="100%" height="fitContent" style={{marginBottom:'10px'}}></img>

                            <div className='actuLogin' style={{display:'flex', justifyContent:'center'}}>
                                <div style={{width: '60%', 'marginTop':'10px'}}>  
                                    <span style={{'color': '#d86745'}}><b>TVA réduite à 5,5 %</b> : Découvrez nos solutions éligibles pour vos projets !</span>
                                    <br/>Contactez-vite votre commercial
                                    <a href='https://www.socotex.net/post/tva-r%C3%A9duite-%C3%A0-5-5' target="_blank" rel='noreferrer noopener'><img src={process.env.PUBLIC_URL + '/actuSocotex.gif'} alt="Socotex 2025" width="100%" height="fitContent"></img></a>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                
            </div>
            {/* <div style={{display:'flex'}}>

                <div>
                    <h3><b></b></h3>
                    <a href="https://form.jotform.com/242771923565060" target="_blank" rel='noreferrer noopener'>
                        <img src={process.env.PUBLIC_URL + '/soltis-tour-socotex.png'} alt="Formulaire soltis tour socotex" width="10%" height="fitContent"></img>
                    </a>
                </div>
                <div>
                    <h3><b></b></h3>
                    <a href="https://form.jotform.com/242771923565060" target="_blank" rel='noreferrer noopener'>
                        <img src={process.env.PUBLIC_URL + '/soltis-tour-socotex.png'} alt="Formulaire soltis tour socotex" width="25%" height="fitContent"></img>
                    </a>
                </div>

            </div> */}
        </div>
    )
}

export default Login